import {
  MemberJoinControllerService,
  SocialJoinControllerService,
  SupportModuleControllerService,
} from '@/__generated__/CommonApi';
import iconFacebook from '@/assets/img/join/ico_facebook@2x.png';
import iconGoogle from '@/assets/img/join/ico_google@2x.png';
import iconNaver from '@/assets/img/join/ico_naver@2x.png';
import Agreement from '@/components/Agreement';
import AnchorText from '@/components/AnchorText';
import Button from '@/components/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup, { FormItem } from '@/components/Form/FormGroup';
import Label from '@/components/Form/Label';
import SuccessMessage from '@/components/Form/SuccessMessage';
import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import HR from '@/components/HR';
import InputCheckbox from '@/components/Input/InputCheckbox';
import InputNumber from '@/components/Input/InputNumber';
import InputPassword from '@/components/Input/InputPassword';
import InputText from '@/components/Input/InputText';
import Select from '@/components/Select';
import { NaverSuccessResult } from '@/components/SocialLogin/NaverLogin';
import { NotiBox, NotiItem, NotiList } from '@/components/Text/TextNoti';
import NiceAuthenticationForm from '@/components/ThirdParty/NiceAuthenticationForm';
import { H2, H4 } from '@/components/Titles';
import {
  HomepageAgreeContent,
  JoinPrivacyAgreeContent,
} from '@/data/agreements';
import { breakpoint } from '@/helpers/BreakpointHelper';
import GtmHelper from '@/helpers/GtmHelper';
import { emailRegex, mobileRegex } from '@/helpers/ValidationHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import {
  FormButton,
  FormContainer,
  LeftCol,
  RightCol,
} from '@/page-blocks/auth/AuthCommon';
import { usePopupStore } from '@/stores/PopupStore';
import dayjs from 'dayjs';
import { navigate, PageProps } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

const JoinForm = styled.form`
  padding-top: 96px;
  ${breakpoint(`mobile`)} {
    padding-top: 46px;
  }
`;

const Description = styled.span`
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.8px;
  ${breakpoint(`mobile`)} {
    font-size: 12px;
  }
`;

const SnsLoginText = styled.div`
  margin-bottom: 72px;
  img {
    width: 48px;
    height: 48px;
    margin-right: 8px;
    ${breakpoint(`mobile`)} {
      width: 40px;
      height: 40px;
    }
  }
  span {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -1.2px;
    vertical-align: middle;
    ${breakpoint(`mobile`)} {
      font-size: 16px;
    }
  }
`;

interface JoinFormData {
  userId: string;
  password: string;
  passwordConfirm: string;
  name: string;
  birthday: string;
  relation: string;
  relationName: string;
  relationTelNumber: string;
  // 인증방법 (휴대폰, 없으면 이메일)
  authType: 'P' | 'M';
  mobilePhoneNumber: string;
  email: string;
  emailAuthCode: string;
  smsAgree: boolean;
  emailAgree: boolean;
  // 이용약관 동의
  policyAgree: boolean;
}

interface GoogleLocationState {
  email: string;
  name: string;
  sub: string;
}
export interface JoinLocationState {
  naver?: NaverSuccessResult;
  facebook?: ReactFacebookLoginInfo;
  google?: GoogleLocationState;
  existEamilPass?: boolean;
}

// 소셜로그인 인증정보
interface SocialData {
  // 필수
  socialId: string;
  socialType: 'N' | 'F' | 'G'; // N : 네이버, F : 페이스북, G : 구글
  // 기타
  email?: string;
  name?: string;
}

const JoinFormPage: FC<
  PageProps<any, any, JoinLocationState | null>
> = observer(({ location }) => {
  // 네이버 소셜 로그인 결과값
  const socialData: SocialData | null = useMemo(() => {
    if (location.state) {
      if (location.state.naver) {
        const { id, email, name } = location.state.naver.user;
        return {
          socialId: id,
          socialType: `N`,
          email,
          name,
        };
      }
      if (location.state.facebook) {
        const { id, email, name } = location.state.facebook;
        return {
          socialId: id,
          socialType: `F`,
          email,
          name,
        };
      }
      if (location.state.google) {
        const { sub, name, email } = location.state.google;
        return {
          socialId: sub,
          socialType: `G`,
          name,
          email,
        };
      }
    }
    return null;
  }, [location.state]);

  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    unregister,
    clearErrors,
    getValues,
    control,
    formState: { errors },
  } = useForm<JoinFormData>({
    mode: `onChange`,
    defaultValues: {
      name: socialData?.name || ``,
      email: socialData?.email || ``,
      birthday: ``,
      relationTelNumber: ``,
      mobilePhoneNumber: ``,
      policyAgree: false,
      authType: `P`,
    },
  });
  const popupStore = usePopupStore();
  const authType = watch(`authType`);
  const email = watch(`email`);

  // 중복확인 통과한 아이디 임시저장
  const [confirmedUserId, setConfirmedUserId] = useState<string>(``);
  // 인증코드 보낸 이메일 임시저장
  const [authCodeSentEmail, setAuthCodeSentEmail] = useState<string>(``);
  const isEmailAuthCodeSent =
    !!authCodeSentEmail && authCodeSentEmail === email;
  // 이메일 인증코드 확인 여부
  const [isEmailAuthCodeValid, setIsEmailAuthCodeValid] = useState<boolean>(
    false,
  );
  // 본인인증 통과여부
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [isValidRelationPhone, setIsValidRelationPhone] = useState(false);

  const birthdayInput = watch(`birthday`);
  // 생일을 dayjs 인스턴스로 변환
  const birthday = useMemo(
    () => (birthdayInput?.length === 8 ? dayjs(birthdayInput, `YYYYMMDD`) : null),
    [birthdayInput],
  );
  const age = useMemo(() => birthday && dayjs().diff(birthday, `year`, true), [
    birthday,
  ]);
  // 14세 미만 여부
  const isUnderFourteen = age !== null && age < 14 && age >= 0;
  // 휴대폰 필수 여부 (소셜가입이거나, 인증타입이 휴대폰일때)
  const isPhoneRequired = !!socialData || authType === `P`;

  const onSubmit: SubmitHandler<JoinFormData> = useCallback(
    async ({
      policyAgree,
      emailAgree,
      smsAgree,
      birthday: birthdayValue,
      ...formData
    }) => {
      // 일반가입이면 아이디 중복확인 필요
      if (!socialData && confirmedUserId !== formData.userId) {
        setError(`userId`, {
          type: `duplicate`,
          message: `중복확인이 필요합니다`,
          shouldFocus: true,
        });

        return;
      }
      if (!policyAgree) {
        setError(`policyAgree`, {
          type: `required`,
          message: `이용약관에 동의해주세요`,
          shouldFocus: true,
        });
        return;
      }

      try {
        const finalData = {
          ...formData,
          emailAgreeYn: emailAgree ? `Y` : `N`,
          smsAgreeYn: smsAgree ? `Y` : `N`,
          birthday:birthdayValue,
        };

        // 소셜 가입
        if (socialData) {
          const {
            resultCode,
            data: _data,
          } = await SocialJoinControllerService.socialJoinUsingPost({
            ...socialData,
            ...finalData,
            // 이메일 중복가입여부
            existEamilPassYn: location.state?.existEamilPass ? `Y` : `N`,
          });

          const data = _data as {
            userId: string;
            exsitUserId?: string;
          };

          // 가입 성공하면
          if (resultCode === `success`) {
            // GTM 이벤트 발생 (소셜가입)
            GtmHelper.join({
              userId: data.userId,
              isSuccess: true,
              socialType: socialData.socialType,
            });
            if (data.exsitUserId) {
              popupStore.show(
                `기존에 ${data.exsitUserId} 계정으로 연결되어 있습니다.\n현재 아이디로 후원 연결을 계속 진행하시겠습니까?`,
                {
                  showCancelButton: true,
                  onConfirm: (agree) => {
                    if (agree) {
                      SupportModuleControllerService.setAutoConnectionByJoinUserIdUsingPost(
                        {
                          birthOrBizNumber: finalData.birthday.substr(2, 6),
                          mobilePhoneNumber: finalData.mobilePhoneNumber,
                          name: finalData.name,
                          // 후원인 구분( A : 개인후원자 )
                          supportGubun: `A`,
                          userId: data.userId,
                        },
                      ).then(() => {
                        // 성공후 완료페이지 이동
                        navigate(`/auth/join/complete`);
                      });
                    } else {
                      // 완료페이지 이동
                      navigate(`/auth/join/complete`);
                    }
                  },
                },
              );
              return;
            }
          }
        }
        // 일반가입
        else {
          await MemberJoinControllerService.memberJoinUsingPost(finalData);
          // GTM 이벤트 발생 (일반가입)
          GtmHelper.join({
            userId: formData.userId,
            isSuccess: true,
          });
        }

        // 회원가입 성공후 완료페이지 이동
        navigate(`/auth/join/complete`);
      } catch (e) {
        console.error(e);
        // 실패시 메세지 출력
        if (e.response) {
          popupStore.show(
            e.response.data?.data?.returnMessage ||
              `서버 에러. 관리자에 문의해주세요.`,
          );
        }
      }
    },
    [
      confirmedUserId,
      location.state?.existEamilPass,
      popupStore,
      setError,
      socialData,
    ],
  );

  // 중복 확인
  const checkDuplicateId = useCallback(async () => {
    const userIdValue = getValues(`userId`);
    const {
      resultCode,
    } = await MemberJoinControllerService.duplicateCheckUserIdUsingGet({
      userId: userIdValue,
    });

    // 체크한 아이디 저장
    if (resultCode === `success`) {
      setConfirmedUserId(userIdValue);
      // validation 에러 제거
      clearErrors(`userId`);
    } else {
      setError(`userId`, {
        type: `duplicate`,
        message: `이미 등록된 아이디 입니다`,
      });
    }
  }, [clearErrors, getValues, setError]);

  // 이메일 인증코드 발송
  const sendEmailAuthCode = useCallback(async () => {
    // 주소가 있고 에러가 없으면
    if (email && !errors.email) {
      // 발송
      try {
        const {
          data,
          resultCode,
        } = await MemberJoinControllerService.emailAuthCodeUsingGet({
          emailAuthUseType: `join`,
          reciveMailAddr: email,
        });
        if (resultCode === `success`) {
          setAuthCodeSentEmail(email);
        } else {
          popupStore.show(data?.resultMessage);
        }
      } catch (e) {
        if (e.response) {
          popupStore.show(e.response.data.data.resultMessage);
        }
      }
    }
  }, [email, errors.email, popupStore]);

  // 이메일 인증코드 검증
  const validateEmailAuthCode = useCallback(async () => {
    const params = getValues([`email`, `emailAuthCode`]);
    // 코드가 있으면
    if (params.emailAuthCode) {
      // 확인
      try {
        const {
          resultCode,
        } = await MemberJoinControllerService.emailAuthCheckUsingPost(params);
        if (resultCode === `success`) {
          setIsEmailAuthCodeValid(true);
          clearErrors(`emailAuthCode`);
          // 함수 종료
          return;
        }
      } catch (e) {
        console.error(e.response);
      }
      // 나머지 경우는 실패
      setError(`emailAuthCode`, {
        type: `invalid`,
        message: `인증코드가 올바르지 않습니다.`,
      });
    }
  }, [clearErrors, getValues, setError]);

  return (
    <LayoutWithTitle
      location={location}
      title="회원가입"
      description="유니세프한국위원회에 오신것을 환영합니다"
    >
      <FormContainer>
        <JoinForm onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" name="authType" ref={register} />
          {socialData?.socialType === `N` && (
            <SnsLoginText>
              <img src={iconNaver} alt="네이버" />
              <span>네이버 아이디로 가입 중입니다</span>
            </SnsLoginText>
          )}
          {socialData?.socialType === `F` && (
            <SnsLoginText>
              <img src={iconFacebook} alt="페이스북" />
              <span>페이스북 아이디로 가입 중입니다</span>
            </SnsLoginText>
          )}
          {socialData?.socialType === `G` && (
            <SnsLoginText>
              <img src={iconGoogle} alt="구글" />
              <span>구글 아이디로 가입 중입니다</span>
            </SnsLoginText>
          )}
          {!socialData && (
            <>
              <FormGroup>
                <Label required>아이디</Label>
                <Row>
                  <Col desktop="2">
                    <InputText
                      name="userId"
                      ref={register({
                        required: true,
                        minLength: {
                          value: 8,
                          message: `8자 이상이어야 합니다`,
                        },
                        maxLength: {
                          value: 16,
                          message: `16자 이하여야 합니다`,
                        },
                        pattern: {
                          value: /^[a-z0-9-_]+$/,
                          message: `영문 소문자, 숫자, (-)(_)만 사용가능합니다`,
                        },
                        validate: (value) =>
                          (value && confirmedUserId === value) ||
                          `중복확인이 필요합니다`,
                      })}
                      placeholder="8-16자의 영문 소문자, 숫자, (-)(_)"
                      autoComplete="off"
                    />
                  </Col>
                  <RightCol desktop="none">
                    <FormButton
                      outline
                      onClick={checkDuplicateId}
                      disabled={
                        !getValues(`userId`) ||
                        (!!errors.userId &&
                          errors.userId.type !== `validate`) ||
                        confirmedUserId === getValues(`userId`)
                      }
                    >
                      중복확인
                    </FormButton>
                  </RightCol>
                </Row>
                {errors.userId && (
                  <ErrorMessage>{errors.userId.message}</ErrorMessage>
                )}
                {getValues(`userId`) && !errors.userId && (
                  <SuccessMessage>사용가능한 아이디 입니다</SuccessMessage>
                )}
              </FormGroup>
              <FormGroup>
                <Label required>비밀번호</Label>
                <InputPassword
                  name="password"
                  ref={register({
                    required: true,
                    minLength: {
                      value: 8,
                      message: `8자 이상이어야 합니다`,
                    },
                    maxLength: {
                      value: 16,
                      message: `16자 이하여야 합니다`,
                    },
                    pattern: {
                      value: /(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%*?&^()+=-])/,
                      message: `영문 및 숫자,특수문자를 모두 포함해야 합니다`,
                    },
                  })}
                  placeholder="8-16자의 영문 및 숫자,특수문자를 모두 포함"
                />
                {errors.password && (
                  <ErrorMessage>{errors.password.message}</ErrorMessage>
                )}
              </FormGroup>
              <FormGroup>
                <Label required>비밀번호 확인</Label>
                <InputPassword
                  name="passwordConfirm"
                  ref={register({
                    required: true,
                    validate: (value) =>
                      value === getValues(`password`) ||
                      `비밀번호가 일치하지 않습니다`,
                  })}
                  placeholder="8-16자의 영문 및 숫자,특수문자를 모두 포함"
                />
                {errors.passwordConfirm && (
                  <ErrorMessage>{errors.passwordConfirm.message}</ErrorMessage>
                )}
              </FormGroup>
            </>
          )}

          <FormGroup>
            <Label required>성명·단체명</Label>
            <InputText
              name="name"
              ref={register({ required: true })}
              placeholder="성명·단체명 입력"
            />
            {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
          </FormGroup>
          <FormGroup>
            <Label required>생년월일</Label>
            <Controller
              control={control}
              name="birthday"
              rules={{
                required: {
                  value: true,
                  message: `필수 입력입니다`,
                },
                validate: (value) =>
                  dayjs(value, `YYYYMMDD`, true).isValid() ||
                  `올바르지 않은 형식입니다`,
              }}
              as={
                <InputNumber
                  format="########"
                  placeholder="생년월일 8자리 입력(YYYYMMDD)"
                />
              }
            />
            <Description>
              법령에 따라 만 14세 미만 아동 여부를 확인하기 위해 가입자 본인의
              생년월일을 입력해 주세요.
            </Description>
            {errors.birthday && (
              <ErrorMessage>{errors.birthday.message}</ErrorMessage>
            )}
          </FormGroup>
          {/* 14세 미만일때 법정대리인 정보 입력 */}
          {isUnderFourteen && (
            <FormItem
              bgGray
              noBorder
              css={`
                padding: 24px 0;
              `}
            >
              <H4>법정대리인 정보</H4>
              <FormGroup>
                <Label required>법정대리인명</Label>
                <InputText
                  name="relationName"
                  ref={register({ required: true })}
                  placeholder="법정대리인명 입력"
                  readOnly={isValidRelationPhone}
                />
                {errors.relationName && (
                  <ErrorMessage>{errors.relationName.message}</ErrorMessage>
                )}
              </FormGroup>
              <FormGroup>
                <Label required>본인과의 관계</Label>
                <Select name="relation" ref={register}>
                  <option value="">관계 선택</option>
                  <option>부모</option>
                  <option>친척</option>
                  <option>지인</option>
                  <option>선생님</option>
                  <option>기타</option>
                </Select>
                {errors.relation && (
                  <ErrorMessage>{errors.relation.message}</ErrorMessage>
                )}
              </FormGroup>
              <FormGroup noMargin>
                <Label required>법정대리인 휴대폰번호</Label>
                <Row>
                  <LeftCol style={{ flexBasis: 'auto' }} desktop="none">
                    {isValidRelationPhone ? (
                      <FormButton outline color="grey" disabled>
                        인증완료
                      </FormButton>
                    ) : (
                      <NiceAuthenticationForm
                        onSuccess={(result) => {
                          setIsValidRelationPhone(true);
                          setValue(`relationTelNumber`, result.mobile);
                          clearErrors(`relationTelNumber`);
                        }}
                        renderSubmitButton={(props) => (
                          <FormButton
                            outline
                            disabled={authType !== `P`}
                            color={authType === `P` ? `blue` : `grey`}
                            {...props}
                          >
                            본인인증
                          </FormButton>
                        )}
                      />
                    )}
                  </LeftCol>
                  <Col desktop="auto">
                    <Controller
                      control={control}
                      name="relationTelNumber"
                      rules={{
                        required: {
                          value: true,
                          message: `필수 입력입니다`,
                        },
                        pattern: {
                          value: mobileRegex,
                          message: `올바른 휴대폰번호가 아닙니다`,
                        },
                        validate: {
                          isValidRange: (value) =>
                            (value.substr(0, 3) === `010` &&
                              value.length === 11) ||
                            (value.substr(0, 3) !== `010` &&
                              value.length === 10) ||
                            `휴대폰번호 자릿수를 확인해주세요`,
                        },
                      }}
                      as={
                        <InputNumber
                          format="###########"
                          placeholder="휴대폰번호 입력 ( &lsquo;-&rsquo; 제외 )"
                          disabled={authType !== `P`}
                          readOnly
                        />
                      }
                    />
                  </Col>
                </Row>
                {errors.relationTelNumber && (
                  <ErrorMessage>
                    {errors.relationTelNumber.message}
                  </ErrorMessage>
                )}
              </FormGroup>
            </FormItem>
          )}
          {!isUnderFourteen && authType !== 'M' && (
            <>
              <FormGroup
                css={`
                  margin-top: 24px;
                `}
              >
                <Label required={isPhoneRequired}>휴대폰번호</Label>
                <Row>
                  <LeftCol desktop="none">
                    {isValidPhone ? (
                      <FormButton outline color="grey" disabled>
                        인증완료
                      </FormButton>
                    ) : (
                      <NiceAuthenticationForm
                        onSuccess={(result) => {
                          setIsValidPhone(true);
                          setValue(`mobilePhoneNumber`, result.mobile);
                          clearErrors(`mobilePhoneNumber`);
                        }}
                        renderSubmitButton={(props) => (
                          <FormButton
                            outline
                            disabled={authType !== `P`}
                            color={authType === `P` ? `blue` : `grey`}
                            {...props}
                          >
                            본인인증
                          </FormButton>
                        )}
                      />
                    )}
                  </LeftCol>
                  <Col
                    desktop="auto"
                    css={`
                      position: relative;
                      z-index: -1;
                    `}
                  >
                    <InputText
                      name="mobilePhoneNumber"
                      ref={register({
                        required: {
                          value: isPhoneRequired,
                          message: `필수 입력입니다`,
                        },
                        pattern: {
                          value: mobileRegex,
                          message: `올바른 휴대폰번호가 아닙니다`,
                        },
                        validate: {
                          isValidRange: (value) =>
                            (value.substr(0, 3) === `010` &&
                              value.length === 11) ||
                            (value.substr(0, 3) !== `010` &&
                              value.length === 10) ||
                            `휴대폰번호 자릿수를 확인해주세요`,
                        },
                      })}
                      placeholder="휴대폰번호 입력 ( &lsquo;-&rsquo; 제외 )"
                      disabled={authType !== `P`}
                      readOnly={!isUnderFourteen}
                    />
                  </Col>
                </Row>

                {errors.mobilePhoneNumber && (
                  <ErrorMessage>
                    {errors.mobilePhoneNumber.message}
                  </ErrorMessage>
                )}
                {!socialData && (
                  <AnchorText
                    css={`
                      margin-top: 8px;
                    `}
                    onClick={() => {
                      setValue(`authType`, `M`);
                      unregister(`mobilePhoneNumber`);
                    }}
                  >
                    휴대폰이 없으신가요?
                  </AnchorText>
                )}
              </FormGroup>
              <HR />
            </>
          )}

          <FormGroup
            css={`
              margin: 16px 0;
            `}
          >
            <Label required>이메일</Label>
            <Row>
              <Col desktop="auto">
                <InputText
                  name="email"
                  ref={register({
                    required: true,
                    pattern: {
                      value: emailRegex,
                      message: `올바르지 않은 이메일주소 입니다`,
                    },
                  })}
                  placeholder="이메일주소 입력"
                  readOnly={!!socialData?.email || isEmailAuthCodeValid}
                />
              </Col>
              {authType === `M` && !socialData && (
                <RightCol desktop="none">
                  <FormButton
                    outline
                    onClick={sendEmailAuthCode}
                    disabled={isEmailAuthCodeSent && isEmailAuthCodeValid}
                  >
                    본인인증
                  </FormButton>
                </RightCol>
              )}
            </Row>
            {errors.email && (
              <ErrorMessage>{errors.email.message}</ErrorMessage>
            )}
            {isEmailAuthCodeSent && (
              <SuccessMessage>인증코드메일이 발송되었습니다.</SuccessMessage>
            )}
          </FormGroup>
          {isEmailAuthCodeSent && (
            <FormGroup>
              <Row>
                <Col desktop="2">
                  <InputText
                    name="emailAuthCode"
                    ref={register({
                      required: {
                        value: true,
                        message: `인증코드를 입력해주세요`,
                      },
                      validate: (value) =>
                        (value &&
                          isEmailAuthCodeSent &&
                          isEmailAuthCodeValid) ||
                        `인증코드 확인이 필요합니다`,
                    })}
                    placeholder="인증코드 입력"
                    readOnly={isEmailAuthCodeValid}
                  />
                </Col>
                {authType === `M` && (
                  <RightCol desktop="none">
                    {isEmailAuthCodeValid ? (
                      <FormButton color="grey" disabled>
                        인증완료
                      </FormButton>
                    ) : (
                      <FormButton outline onClick={validateEmailAuthCode}>
                        확인
                      </FormButton>
                    )}
                  </RightCol>
                )}
              </Row>
              {errors.emailAuthCode && (
                <ErrorMessage>{errors.emailAuthCode.message}</ErrorMessage>
              )}
            </FormGroup>
          )}
          <FormGroup
            css={`
              margin-bottom: 0;
            `}
          >
            <Label>전 세계 어린이를 위한 소식·후원·캠페인 안내 수신동의(선택)</Label>
            <Row
              css={`
                margin-top: 4px;
                margin-bottom: 12px;
              `}
            >
              <Col>
                <InputCheckbox label="모바일" name="smsAgree" ref={register} />
              </Col>
              <Col>
                <InputCheckbox
                  label="이메일"
                  name="emailAgree"
                  ref={register}
                />
              </Col>
            </Row>
            <NotiBox
              css={`
                margin-bottom: 0;
              `}
            >
              <NotiList>
                <NotiItem>
                  <strong>
                    공지사항, 긴급구호 및 회원관리 안내는 수신 여부와 관계없이
                    발송됩니다.
                  </strong>
                </NotiItem>
                <NotiItem>
                  <strong>
                    모바일을 선택하시면 가장 빠르게 소식을 받아 보실 수 있습니다.
                  </strong>
                </NotiItem>
                <NotiItem>
                  동의 내용은 언제든지 웹사이트 또는 전화상담을 통해 변경이
                  가능합니다.
                </NotiItem>
              </NotiList>
            </NotiBox>
          </FormGroup>
          <FormItem
            css={`
              padding: 96px 0 92px 0;
            `}
          >
            <H2
              css={`
                ${breakpoint(`mobile`)} {
                  font-size: 20px;
                }
              `}
            >
              약관 및 개인정보 수집·이용 동의
            </H2>
            <HR
              color="#b7b8ba"
              css={`
                margin: 16px 0;
              `}
            />
            <Agreement
              name="policyAgree"
              allChk
              ref={register({
                required: {
                  value: true,
                  message: `이용약관에 동의해주세요`,
                },
              })}
              policies={[
                {
                  label: `웹사이트 이용약관 동의`,
                  content: HomepageAgreeContent,
                  required: true,
                },
                {
                  label: `개인정보 수집 ∙ 이용 동의`,
                  content: JoinPrivacyAgreeContent,
                  required: true,
                },
              ]}
            />
            {errors.policyAgree && (
              <ErrorMessage>{errors.policyAgree.message}</ErrorMessage>
            )}
          </FormItem>
          <Button type="submit" full>
            가입하기
          </Button>
        </JoinForm>
      </FormContainer>
    </LayoutWithTitle>
  );
});

export default JoinFormPage;
